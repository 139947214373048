import loadJS from '@neonaut/lib-js/es/dom/network/load-js';

import readSpEnv from '../helpers/read-sp-env';
import cacheBustUrl from '../helpers/cacheBustUrl';

let loadedPromise = null;

const defaultLanguage = 'de-de';
const languageMap = {
	de: 'de-de',
	// Not enabled for bs
	//zh: 'zh-cn',
	//en: 'en-us',
	//fr: 'fr-fr',
	//it: 'it-it',
	//pl: 'pl-pl',
	//pt: 'pt-pt',
	//ru: 'ru-ru',
	//es: 'es-es',
	//tr: 'tr-tr',
};

export function mapLanguageCode(code) {
	return languageMap[code] ?? defaultLanguage;
}

export function withVoiceReader(cb) {
	if (loadedPromise === null) {
		loadedPromise = new Promise((resolve, reject) => {
			const resourcesPath = readSpEnv('resourcesPath');
			loadJS(
				cacheBustUrl(
					resourcesPath.trim('/') + '/js/chunk-voice-reader.js'
				),
				() => {
					console.info('voice reader chunk loaded');

					// We need to wait for async jQuery.ready() inside the chunk, before we are ready
					if (!window.___jQuery) {
						console.info('jquery missing');
						reject('could not load voice reader');
						return;
					}

					console.info('adding ready handler');
					window.___jQuery(function handleVoiceReaderReady() {
						console.info('voice reader ready');
						resolve();
					});
				}
			);
		});
	}

	loadedPromise.then(cb);
}
