import {mapLanguageCode, withVoiceReader} from '../helpers/voice-reader';
import {getCurrentLanguage} from '../helpers/i18n';

function handleButtonClick() {
	window._paq = window._paq || [];
	window._paq.push(['trackEvent', 'VoiceReader', 'Open']);

	withVoiceReader(function () {
		// noinspection JSUnresolvedFunction
		if ('___vrweb_embed_player_0' in window) {
			window.___vrweb_embed_player_0(
				undefined,
				undefined,
				mapLanguageCode(getCurrentLanguage())
			);
		} else {
			console.error('Could not load voice reader!');
		}
	});
}

export function updateVoiceReaderButtons(lang = getCurrentLanguage()) {
	document
		.querySelectorAll('.js-bs-voice-reader')
		.forEach(function renderVoiceReader(buttonElement) {
			if (lang === 'de') {
				buttonElement.classList.remove('hidden');
				buttonElement.addEventListener('click', handleButtonClick);
			} else {
				buttonElement.classList.add('hidden');
				buttonElement.removeEventListener('click', handleButtonClick);
			}
		});
}

updateVoiceReaderButtons();
